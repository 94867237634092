@import 'styles/layers.scss';

@layer component {
  .container {
    margin-top: 25px;
  }

  .wrap-title {
    margin-bottom: 27px;
    height: 42px;
    text-transform: capitalize;
  }

  .table-container {
    font-size: 14px;
  }

  .empty-place {
    height: 400px;
    &-not-loading {
      opacity: 0.3;
    }
  }
}